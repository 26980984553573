import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "input" ]

  connect(){
    this.element.querySelectorAll("input").forEach((element) => {
      element.addEventListener("input", this.change.bind(this))
      element.addEventListener("keydown", (event) => {
        if(event.key == "Enter") event.preventDefault();
      })
    })
  }

  disconnect(){
    this.element.querySelectorAll("input").forEach((element) => {
      element.removeEventListener("input", this.change.bind(this))
      element.removeEventListener("keydown", (event) => {
        if(event.key == "Enter") event.preventDefault();
      })
    })
  }

  change = this.debounce(this.onChange, 300, true)

  onChange(event){
    event.stopImmediatePropagation()
    event.preventDefault()
    this.element.requestSubmit()
  }

  debounce(func, wait = 5000) {
    let timeout
    return function(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = undefined;
        func.apply(this, args);
      }, wait);
    };
  }
}