import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";


export default class extends Controller {
  static targets = [ "content", "subcontent", "quantity" ]

  connect() {
    useClickOutside(this)

    if(this.hasQuantityTarget) {
      this.element.querySelectorAll("input").forEach((elt) => elt.addEventListener("change", (event) => {
        this.quantityTarget.innerHTML = this.element.querySelectorAll("input:checked").length
      }))
    }
  };
  
  toggle(event){
    event.preventDefault()
    if (this.hasContentTarget) {
      this.contentTarget.classList.toggle("show")
    }
  }

  subtoggle(event){
    event.preventDefault()
    event.stopPropagation()
    let scope = event.currentTarget.dataset.scope
    if(this.hasSubcontentTarget) {
      this.subcontentTargets.forEach((div) => {
        if (div.id != scope) div.classList.remove("show")
      })
    }

    document.getElementById(event.currentTarget.dataset.scope).classList.toggle("show")
  }

  clickOutside(){
    this.contentTarget.classList.remove("show")
    this.subcontentTargets.forEach(sub_content => sub_content.classList.remove("show"))
  }
}
