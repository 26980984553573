import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["open", "search", "input", "options", "value"]

  connect() {
    this.url = this.element.dataset.url
    useClickOutside(this)
  }

  open(event){
    event.target.classList.toggle("open")
    if (event.target.classList.contains("open")){
      this.inputTarget.focus()
    }
  }

  choose(event) {
    this.searchTarget.innerHTML = event.currentTarget.dataset.label
    this.clickOutside()
    this.valueTarget.value = event.currentTarget.dataset.value
    this.valueTarget.dataset.label = event.currentTarget.dataset.label
    this.valueTarget.dispatchEvent(new Event("change"))
  }

  search = this.debounce(this.onSearch, 300, true)

  onSearch(event){
    let query = event.target.value
    // let without_space = query.replace(/\s/g, "")
    // if(without_space != "" && without_space.length > 1){
      this.fetchResults(query)
    // } else {
    //   this.optionsTarget.innerHTML = ""
    // }
  }

  fetchResults = async (query) => {
    const url = this.buildURL(query)

    try {
      this.element.dispatchEvent(new CustomEvent("loadstart"))
      const html = await this.doFetch(url)
      Turbo.renderStreamMessage(html)
      this.element.dispatchEvent(new CustomEvent("load"))
      this.element.dispatchEvent(new CustomEvent("loadend"))
    } catch (error) {
      this.element.dispatchEvent(new CustomEvent("error"))
      this.element.dispatchEvent(new CustomEvent("loadend"))
      throw error
    }
  }

  buildURL(query) {
    const url = new URL(this.url, window.location.href)
    const params = new URLSearchParams(`q=${query}`)
    url.search = params.toString()

    return url.toString()
  }

  doFetch = async (url) => {
    const response = await fetch(url, this.fetchOptions())

    if(!response.ok){
      throw new Error(`Server responded with status ${response.status}`)
    }

    const html = await response.text()

    return html
  }

  fetchOptions() {
    return { headers: { "X-Requested-With": "XMLHttpRequest" }}
  }
  
  debounce(func, wait = 1000, fastFirstCall = false) {
    let timeout
    let firstCalledResetTimeout
    return function(...args) {
      if(fastFirstCall && !firstCalledResetTimeout) {
        func.apply(this, args);
        firstCalledResetTimeout = setTimeout(() => {
          firstCalledResetTimeout = undefined;
        }, wait * 2);
        return;
      } else if (firstCalledResetTimeout){
        clearTimeout(firstCalledResetTimeout);
        firstCalledResetTimeout = setTimeout(() => {
          firstCalledResetTimeout = undefined;
        }, wait * 2);
      }
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = undefined;
        func.apply(this, args);
      }, wait);
    };
  }

  clickOutside(){
    this.openTarget.classList.remove("open")
  }

  preventEnter(event){
    event.preventDefault()
  }
}