import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect(){
    document.getElementById("composed_of_id").addEventListener("change", (event) => this.getOptions(event))
  }

  getOptions = async (event) => {
    const url = this.buildURL(`/admins/products/${event.target.value}/units.turbo_stream`)
    try {
      this.element.dispatchEvent(new CustomEvent("loadstart"))
      const html = await this.doFetch(url)
      Turbo.renderStreamMessage(html)
      this.element.dispatchEvent(new CustomEvent("load"))
      this.element.dispatchEvent(new CustomEvent("loadend"))
    } catch (error) {
      this.element.dispatchEvent(new CustomEvent("error"))
      this.element.dispatchEvent(new CustomEvent("loadend"))
      throw error
    }
  }

  buildURL(path) {
    const url = new URL(path, window.location.href)

    return url.toString()
  }

  doFetch = async (url) => {
    const response = await fetch(url, this.fetchOptions())

    if(!response.ok){
      throw new Error(`Server responded with status ${response.status}`)
    }

    const html = await response.text()

    return html
  }

  fetchOptions() {
    return { headers: { "X-Requested-With": "XMLHttpRequest" }}
  }
}