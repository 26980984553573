import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["optionsList", "optionsDisplay", "placeholder"];

  connect() {
    useClickOutside(this)
  };

  open() {
    this.optionsListTarget.classList.toggle("hide");
  }

  check(event) {
    if (event.target.checked){
      if(this.hasPlaceholderTarget)
        this.placeholderTarget.remove();
      this.insert(event.target.id)
    } else {
      this.optionsDisplayTarget.querySelector(`span[data-id="${event.target.id}"]`).remove()
      this.placeholder()
    }
  }

  clickOutside(){
    this.optionsListTarget.classList.add("hide")
  }

  insert(value){
    this.optionsDisplayTarget.insertAdjacentHTML("beforeend", `
      <span data-id="${value}" class="multiselect__option-display" data-action="click->forms--inputs--multi-select#remove">${value}</span>`)
  }

  remove(event){
    event.stopImmediatePropagation()
    const id = event.target.dataset.id
    const checkbox = this.optionsListTarget.querySelector(`#${id}`)
    checkbox.checked = false
    event.target.remove()
    this.placeholder()
  }

  placeholder(){
    if (this.optionsDisplayTarget.children.length == 0)
      this.optionsDisplayTarget.insertAdjacentHTML("beforeend", `<span data-forms--inputs--multi-select-target="placeholder">Sélectionner</span>`)
  }
}
