import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["label", "select"]

  connect() {
    let jsonVolume = JSON.parse(this.selectTarget.dataset.volume)
    this.dataVolume = `<option value>Sélectionner</option>` + Object.keys(jsonVolume).map((key) => `<option value="${key}">${jsonVolume[key]}</option>`).join()
    let jsonPortion = JSON.parse(this.selectTarget.dataset.portion)
    this.dataPortion = `<option value>Sélectionner</option>` + Object.keys(jsonPortion).map((key) => `<option value="${key}">${jsonPortion[key]}</option>`).join()

    this.select = this.selectTarget.querySelector("select")
    this.element.querySelectorAll("input[type=radio]").forEach((elt) => {
      elt.addEventListener("change", (input) => {
        this.change_selector(input.target.value)
      })
    })
  }

  disconnect() {
    this.element.querySelectorAll("input[type=radio]").forEach((elt) => {
      elt.removeEventListener("change", (input) => {
        this.change_selector(input.target.value)
      })
    })
  }

  change_selector(value){
    switch(value){
      case "portion":
        this.labelTarget.innerHTML = "Portion"
        this.select.innerHTML = this.dataPortion
        break;
      case "volume":
        this.labelTarget.innerHTML = "Volume de la recette"
        this.select.innerHTML = this.dataVolume
        break;
    }
  }
}