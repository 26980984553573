import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"]
  
  toggle(event) {
    event.stopImmediatePropagation()
    if(this.hasInputTarget){
      this.inputTarget.type = this.inputTarget.type == "password" ? "text" : "password"
    }
  }
}
