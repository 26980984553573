export default function debounce(func, wait = 1000, fastFirstCall = false) {
  let timeout
  let firstCalledResetTimeout
  return function(...args) {
    if(fastFirstCall && !firstCalledResetTimeout) {
      func.apply(this, args);
      firstCalledResetTimeout = setTimeout(() => {
        firstCalledResetTimeout = undefined;
      }, wait * 2);
      return;
    } else if (firstCalledResetTimeout){
      clearTimeout(firstCalledResetTimeout);
      firstCalledResetTimeout = setTimeout(() => {
        firstCalledResetTimeout = undefined;
      }, wait * 2);
    }
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = undefined;
      func.apply(this, args);
    }, wait);
  };
}